.footer {
  display: flex;
  width: 100%;
  background: #0b1925;
  color: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
}

.heading-row {
  margin: 4% 7% 2% 7%;
}

.details-row {
  margin: 0 7% 2% 7%;
}

.content-left {
  display: flex;
  width: 100%;
  align-items: center;
  color: rgb(134, 228, 255);
}

.content-right {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navy-header {
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 36pt;
  font-stretch: condensed;
  padding-left: 20px;
  color: #86e4ff;
}

.footer-navy-logo {
  background-image: url("../../assets/logoNavy.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 87px;
  height: 87px;
}

.contacts-header {
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 36pt;
  font-stretch: condensed;
  color: #86e4ff;
}

.footer-soc-icons-wrapper {
  margin-bottom: 25px;
  display: flex;
  width: 50%;
  align-self: start;
}

.footer-soc-icon {
  padding-left: 30px;
  cursor: pointer;
  display: flex;
  width: 32px;
}

.footer-soc-icon:first-child {
  padding: 0;
}

.footer-soc-icon:hover {
  filter: brightness(0) saturate(100%) invert(79%) sepia(52%) saturate(1176%) hue-rotate(172deg) brightness(109%) contrast(101%);
}

.icon {
  display: flex;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;
}

.icon-location {
  background-image: url("../../assets/logoLocation.svg");
}

.icon-phone {
  background-image: url("../../assets/logoPhone.svg");
}

.icon-message {
  background-image: url("../../assets/logoMessage.svg");
}

.contact-item {
  display: flex;
  width: 100%;
  margin-top: 25px;
  flex-direction: row;
  align-items: center;
}

.contact-item:first-child {
  margin-top: 0;
}

.contact-text {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 15pt;
  align-items: center;
  color: #ffffff;
  margin: 0 0 0 15px;
  text-decoration: none;
}

@media (max-width: 1250px) {
  .content-left {
    width: 50%;
  }
}

@media (max-width: 890px) {

  .row {
    flex-direction: column;
  }

  .heading-row > .content-right {
    margin: 0;
  }

  .content-left {
    width: 100%;
    justify-content: center;
  }

  .contacts-header {
    display: none;
  }

  .footer-soc-icons-wrapper {
    justify-content: center;
  }
}

@media (max-width: 594px) {
  .content-right > div:last-child{
    margin-top: 25px;
  }
}
