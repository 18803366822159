:root {
    --header: 130px;
    --headerInner: 90px;
    --header-height-difference: calc(
      var(--header) - var(--headerInner)
    );
  }

.header {
    z-index: 999;
    display: flex;
    position:fixed;
    top: calc(
        var(--header-height-difference) * 0
      );
    left: 0;
    right: 0;
    width: 100%;
    height:  var(--header);
    background: #001b34;
    color: #86e4ff;
    align-items: center;
    justify-content: center;
    transition: top 0.6s;
}


.header-inner{
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #001b34;
    color: #86e4ff;
    align-items: center;
    justify-content: center;
    height:  var(--headerInner);
}

.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
}

.header-left-content {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-right-content {
    display: flex;
    flex-direction: row;
    width: 10%;
    justify-content: space-around;
}

.container-block-logo {
    font-stretch: condensed;
    font-weight: 800;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #86e4ff;
}

.logobar {
    width: 50%;
    margin: 0 30px;
    display: flex;
}

.navbar {
    display: flex;
    width: 65%;
    justify-content: space-evenly;
}

.style-state-language {
    display: flex;
}

.logoLanguage {
    display: flex;
    background-image: url("../../assets/langIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

.search-button {
    display: flex;
    cursor: pointer;
    background-image: url("../../assets/searchIcon.svg");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
}

.navy-logo {
    display: flex;
    background-image: url("../../assets/logoNavy.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-right: 20px;
    width: 70px;
    height: 73px;
}

.header-soc-icons {
    cursor: pointer;
    display: flex;
    background-size: 100%;
    width: 25px;
    height: 25px;
    margin-left: 20px;
}

.header-soc-icons:hover {
    filter: brightness(0) saturate(100%) invert(79%) sepia(52%) saturate(1176%) hue-rotate(172deg) brightness(109%) contrast(101%);
}

.elipse {
    display: flex;
    background-repeat: no-repeat;
}

.container-block-logo {
    display: flex;
}

.container-block-logo h2 {
    display: flex;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 20px;
    align-items: center;
    padding-left: 10%;
}

.navbar a {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
    color: #ffffff;
}

.nav-item {
    text-decoration: none;
}

.nav-item:hover {
    color: #67DDFF;
}

.hide-block {
    display: none;
}

.slide-in-right-animation {
    -webkit-animation-name: slide-in-right-animation;
    animation-name: slide-in-right-animation;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slide-in-right-animation {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slide-in-right-animation {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@media (max-width: 1440px) {


    .navbar {
        display: none;
    }

    .logobar {
        width: 100%;
    }

    .logoInput {
        background-size: 100%;
    }

    .header-left-content {
        width: 50%;
    }

    .header-right-content {
        justify-content: space-evenly;
        width: 20%;
    }
}

@media (max-width: 744px) {
    .logobar {
        display: none;
    }

    .header-right-content {
        width: 50%;
    }

    .navy-logo {
        height: 50px;
        width: 50px;
        background-size: contain;
    }

    .container-block-logo h2 {
        font-size: 36px;
    }
}
