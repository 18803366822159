.hamburger-menu-wrapper {
  display: none;
}

.hamburger-menu {
    cursor: pointer;
}

.hamburger-menu span {
    height: 2px;
    width: 20px;
    background-color: white;
    margin-top: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.hamburger-menu span:first-child {
    margin-top: 0;
}


.hamburger-menu span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.hamburger-menu span:nth-child(2) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.hamburger-menu span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.hamburger-menu.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

.hamburger-menu.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
}

.menu-active {
  display: block;
  z-index: 1000;
  position: absolute;
  height: 3000px;
  width: 100%;
  top: 130px;
  right: 0;
  transition: 0.2s;
}

.hide {
  display: none;
}

.burger-menu-content {
  float: right;
  width: 320px;
  height: 100%;
  background-color: #001b34;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  transition: 0.4s;
  padding-top: 36px;
}

.burger-menu-content a {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-start;
  color: #ffffff;
  margin-bottom: 15px;
}

.burger-menu-content img {
  margin-bottom: 15px;
}

@media (max-width: 1440px) {
  .hamburger-menu-wrapper {
      display: flex;
      place-items: center;
  }
  .hamburger-menu {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
  }
}
