.vacance-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7%;
    height: 100%;
}
.recruitment-plate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffcf27;
    padding: 2%;
    height: 450px;
    width: 40%;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
.recruitment-center-header {
    font-weight: 600;
    font-size: 28pt;
    text-align: center;
}
.recruitment-phone-header {
    padding-top: 2%;
    font-size: 20pt;
    text-align: center;
}

.recruitment-phones div {
    padding-top: 5%;
    font-size: 14pt;
}
.recruitment-address-header {
    padding-top: 2%;
    font-size: 20pt;
}
.recruitment-address {
    padding-top: 2%;
    font-size: 14pt;
}

@media (max-width: 1100px) {
    .vacance-wrapper {
        flex-direction: column;
    }
    .recruitment-plate {
        width: 100%;
        margin-top: 7%;
    }
}

@media (max-width: 600px) {
    .recruitment-center-header {
        font-size: 20pt;
    }
    .recruitment-phone-header {
        font-size: 16pt;
    }
    .recruitment-phones div {
        font-size: 12pt;
    }
    .recruitment-address-header {
        font-size: 16pt;
    }
    .recruitment-plate {
        height: auto;
        padding-top: 7%;
        padding-bottom: 7%;
    }
}
