.search-result-subheader {
    font-size: 30pt;
    font-weight: 800;
    font-stretch: condensed;
    text-transform: uppercase;
}

.static-search-results {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.static-search-results li {
    width: 49%;
}

.static-results {
    display: block;
}

.search-result-notFound {
    display: flex;
    flex-direction: column;
    font-size: 18pt;
    font-weight: 700;
    font-stretch: expanded;
    text-transform: uppercase;
    align-items: center;
}

.search-warShip {
    background-image: url("../../assets/warship_navy.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 324px;
    width: 645px;
}

.search-main-notFound {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    margin: 5%;
    width: 100%;
    font-size: 30pt;
    font-weight: 700;
    justify-content: center;
    font-stretch: condensed;
    align-items: center;
}

.search-notFound-text-row {
    white-space: nowrap;
    padding: 0 1% 0 1%;
    color: #FFCF27;
    border-bottom: 3px solid currentColor;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title-content-search{
    display: flex;
    margin-top: 3%;
    font-weight: 800;
    font-size: 48pt;
    text-align: center;
    padding: 2%;
    color: #ffffff;
    justify-content: center;
    text-transform: uppercase;
}

@media (max-width: 1400px) {
    .title-content-search{
        font-size: 36pt;
    }
    .search-main-notFound{
        font-size: 25pt;
    }
    .search-notFound-text-row{
        width: 100%
    }
}
@media (max-width: 1000px) {
    .title-content-search{
        font-size: 36pt;
    }
    .search-main-notFound{
        font-size: 20pt;
    }

}

@media (max-width: 744px) {
    .static-search-results li {
        width: 100%;
    }

    .search-warShip {
        height: 180px;
        width: 300px;
        background-size: contain;
    }
    .search-main-notFound{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .title-content-search{
        flex-direction: column;
    }
}
@media (max-width: 580px) {
    .title-content-search{
        flex-direction: column;
        font-size: 30pt;
    }
}