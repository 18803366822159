@font-face {
    font-family: "OpenSans";
    src: local("assets/fonts/OpenSans-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans_Condensed-Bold.ttf");
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans_Condensed-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
    font-stretch: condensed;
}

body {
    margin: 0;
    font-family: "OpenSans", sans-serif;
}
