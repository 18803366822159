.wrapper-all-news {
    width: 100%;
    margin-top: 130px;
    background: url("../../assets/shipFaceNavy.jpg") center/cover fixed
        no-repeat;
    display: flex;
    flex-direction: column;
}

.title-content {
    display: flex;
    margin-top: 3%;
    font-weight: 800;
    font-size: 48pt;
    text-align: center;
    padding: 2%;
    color: #ffffff;
    justify-content: center;
    text-transform: uppercase;
}

.title-black-accent {
    color: black;
}

.area-content {
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    background-color: white;
    margin-right: 6%;
    margin-left: 6%;
    margin-bottom: 6%;
    margin-top: 3%;
    padding-bottom: 2%;
}
.allNews {
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    grid-gap: 3rem;
    width: 100%;
    margin: 1rem 0;
}

.wrraperNewsArea {
    background-color: white;
    margin: 6%;
}

.article-tagX {
    margin-top: 5px;
    background-image: url("../../assets/cross.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
}

.article-tagName {
    padding-left: 10%;
}

.article-tagsFilter {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.article-areaFilter {
    display: flex;
    padding: 4% 0;
    justify-content: flex-end;
    align-items: center;
}

.article-filterButton {
    display: flex;
    background-image: url("../../assets/filterIcon.svg");
    background-repeat: no-repeat;
    width: 97px;
    justify-content: space-between;
}

.article-filterText {
    font-weight: 300;
    font-stretch: condensed;
    text-transform: uppercase;
    font-size: 10pt;
    display: flex;
}
.article-filterButtonText {
    margin-left: 10%;
}

.article-filterButtonArrow {
    background-image: url("../../assets/arrow_bottom.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    width: 15px;
    margin-right: 10%;
}

.filterDropdown {
    margin-left: 15px;
    cursor: pointer;
    padding: 5px 45px 5px 30px;
    font-size: 10pt;
    font-stretch: condensed;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    background-color: #86e4ff;
    border-radius: 10px;
    border: none;
    outline: none;
    background-image: url("../../assets/arrow_bottom.svg");
    background-repeat: no-repeat;
    background-position: 80% 45%;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

.filterDropdown option {
    padding: 5px 20px 5px 20px;
    border: none;
    outline: none;
    font-size: 10pt;
    font-stretch: condensed;
    font-weight: bold;
    text-transform: uppercase;
    background-color: white;
}

.more-new-text {
    color: #000000;
}

@media (max-width: 1000px) {
    .area-content {
        margin-top: 5%;
        padding-top: 3%;
        grid-template-columns: 100%;
    }

    .allNews {
        grid-template-columns: repeat(2, minmax(30%, 1fr));
    }
}

@media (max-width: 744px) {
    .allNews {
        grid-template-columns: repeat(1, minmax(30%, 1fr));
    }
    .area-content {
        margin-right: 0%;
        margin-left: 0%;
    }
    .title-content{
        flex-direction: column;
    }
}

@media (max-width: 580px) {
}
