.notFoundContainer {
    background-color: #072960;
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.notFoundIcon {
    background-image: url("../../assets/NotFoundImage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 700px;
    height: 300px;
    color: white;
}

.erroCodeText {
    color: white;
    font-size: 128px;
    font-weight: 700;
}

.errorText {
    color: white;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0em;
}
