.wrapper {
    display: grid;
}

.first-row {
    height: 370px;
}

.image-container {
    background-image: url("../../assets/shipsImage.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 50%;
    display: flex;
    align-items: center;
    align-content: center;
}

.image-labels-wrapper {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.second-row {
    display: grid;
    grid-template-columns: repeat(2, minmax(30%, 30%));
    grid-gap: 2rem;
    justify-content: center;
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 3%;
    padding-right: 3%;
}

.contactsPlate {
    background-color: #ffcf27;
    height: 15rem;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

.phonesFirstRow {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    font-weight: 700;
    font-size: 30pt;
    font-stretch: condensed;
}

.phoneIcon {
    background-image: url("../../assets/phone.svg");
    background-repeat: no-repeat;
    height: 50px;
    width: 30px;
    margin-right: 30px;
}

.phonesSecondRow {
    display: flex;
    height: 50%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 18pt;
}

.addressFirstRow {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    font-weight: 700;
    font-size: 30pt;
}

.addressIcon {
    background-image: url("../../assets/addressIcon.svg");
    background-repeat: no-repeat;
    height: 50px;
    width: 30px;
    margin-right: 30px;
}

.addressSecondRow {
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18, 75pt;
    font-weight: 300;
}

.emailFirstRow {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    font-weight: 700;
    font-size: 30pt;
}

.emailIcon {
    background-image: url("../../assets/emailIcon.svg");
    background-repeat: no-repeat;
    width: 52px;
    height: 50px;
    margin-right: 30px;
}

.emailSecondRow {
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18, 75pt;
    font-weight: 300;
}

.thirdRow {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.paragraphHead {
    font-weight: 800;
    font-size: 30pt;
    font-stretch: condensed;
    padding-bottom: 7%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.text-blue {
    color: #37c5ee;
}
.text-black-contacts {
    color: #000000;
}

.paragraphText {
    font-weight: 700;
    font-size: 18pt;
    font-stretch: condensed;
    color: #6e6e6e;
}

@media (max-width: 1000px) {
    .paragraphHead {
        flex-direction: column;
    }

    .second-row {
        justify-content: center;
    }

    .second-row {
        grid-template-columns: repeat(1, 70%);
    }
}

@media (max-width: 700px) {
    .second-row {
        grid-template-columns: repeat(auto-fit, minmax(30%, 100%));
    }

    .text-blue,
    .text-black-contacts {
        font-size: 18pt;
    }
    .paragraphText {
        font-size: 16pt;
    }

    .addressFirstRow,
    .phonesFirstRow,
    .emailFirstRow {
        font-size: 24pt;
    }
}
