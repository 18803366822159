.wrapper-navy-face {
    display: flex;
    position: relative;
    background: url("../../assets/mazepa.webp") center/cover fixed no-repeat;
    color: #ffff;
    height: fit-content;
}

.soldier-background {
    position: absolute;
    z-index: 0;
    bottom: 0;
    margin-left: 5%;
    aspect-ratio: 0.85/1;
    width: 42%;
    background-image: url("../../assets/solidImage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left bottom;
    mix-blend-mode: luminosity;
    transition: all 0.2s ease-out;
}

.soldier-background:hover {
    transform: translateX(20px);
    transition: all 0.2s ease-out;
}

.navy-face-text {
    text-align: right;
    display: flex;
    flex-direction: column;
    width: 100%;
    float: right;
    padding: 15% 7% 5% 7%;
}

.navy-face-header {
    align-self: end;
    width: fit-content;
    text-align: left;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 96px;
    color: #ffffff;
    line-height: normal;
}

.navy-face-subheader {
    display: inline-flex;
    font-weight: bold;
    font-size: 48px;
    color: #ffffff;
    padding-left: 35px;
    margin-top: 10px;
}

.anchor-icon {
    display: inline-flex;
    margin-left: 35px;
    background-image: url("../../assets/anchorIcon.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 47px;
    height: 55px;
}

.navy-face-info {
    z-index: 1;
    display: flex;
    align-self: end;
    width: 45%;
    margin-top: 5%;
    background: rgba(0, 25, 46, 0.6);
}

.navy-face-info-content {
    text-align: left;
    margin: 7%;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
}

.fade-in-animation {
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fast-animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.middle-animation {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
}

.last-animation {
    -webkit-animation-duration: 3.5s;
    animation-duration: 3.5s;
}

@media (max-width: 1440px) {
    .navy-face-header, .main-subheader {
        font-size: 70px;
    }

    .navy-face-subheader {
        font-size: 38px;
    }

    .soldier-background {
        width: 45%;
    }
}

@media (max-width: 1240px) {
    .soldier-background {
        width: 55%;
        left: 10%;
    }

    .navy-face-text {
        width: 100%;
        margin-top: 10%;
    }

    .navy-face-header {
        align-self: center;
    }

    .navy-face-info {
        width: 100%;
    }

    .navy-face-header, .main-subheader {
        font-size: 55px;
    }

    .navy-face-subheader {
        font-size: 24px;
    }

}

@media (max-width: 744px) {
    .navy-face-text {
        margin-top: 15%;
        line-height: 0;
    }

    .navy-face-info-content {
        font-size: 16px;
    }

    .navy-face-header, .main-subheader {
        font-size: 45px;
    }

    .navy-face-subheader {
        font-size: 20px;
    }

    .anchor-icon {
        width: 23px;
        height: 27px;
    }
}

@media (max-width: 585px) {
    .wrapper-navy-face * {
        text-align: center;
    }

    .navy-face-text {
        margin-top: 25%;
        padding: 15% 2% 5% 2%;
    }

    .navy-face-subheader {
        display: block;
        padding-left: 0;
    }

    .navy-face-info-content {
        font-size: 16px;
    }

    .navy-face-header, .main-subheader {
        font-size: 40px;
    }

    .navy-face-subheader {
        font-size: 18px;
    }

    .anchor-icon {
        margin-left: 0;
    }
}
