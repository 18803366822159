.wrapper-commander {
  display: flex;
  height: 100%;
  padding-top: 100px;
  background: linear-gradient(50deg, #ffcf27 50%, #fff 50%);
  flex-direction: column;
}

.top-commander {
  display: flex;
  height: 50%;
  justify-content: center;
}

.bottomComander {
  display: flex;
  height: 50%;
  justify-content: center;
}

.container-commander {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 30%));
  justify-content: space-between;
  padding: 0 7% 7% 7%;
}

.commander-plate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  word-wrap: break-word;
}

.text-plateDescription {
  margin: 50px 90px;
  font-size: 18pt;
  font-stretch: condensed;
  color: #6E6E6E;
}

.crossButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  align-self: flex-end;
  background-image: url("../../assets/cross.svg");
  border: none;
  background-color: white;
}

.text-plate {
  grid-column: 2 / span 2;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  word-wrap: break-word;
}

.image-firstPlate {
  background-image: url("../../assets/photoNeihpapa.png");
  background-repeat: no-repeat;
  aspect-ratio: 2/2.3;
  background-size: 100%;
  background-position: center;
}

.image-secondPlate {
  background-image: url("../../assets/photoTarasov.png");
  background-repeat: no-repeat;
  aspect-ratio: 2/2.3;
  background-size: 100%;
  background-position: center;
}

.image-thiredPlate {
  background-image: url("../../assets/photoYrsol.png");
  background-repeat: no-repeat;
  aspect-ratio: 2/2.3;
  background-size: 100%;
  background-position: center;
}

.top-title-commander {
  display: flex;
}

.top-title-commander h1 {
  font-stretch: condensed;
  font-weight: 800;
  font-size: 64px;
  text-transform: uppercase;
}

.blue-title {
  margin: 15% 0;
  color: #37c5ee;
}

.dark-title {
  color: #000000;
}

.name-firstPlate {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  font-stretch: condensed;
  font-weight: bold;
  font-size: 26pt;
  line-height: 35px;
  color: #37c5ee;
  text-align: center;
  margin-top: 35px;
}

.name-firstPlate div {
   margin: auto;
}

.rank-firstPlate {
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  font-stretch: condensed;
  line-height: 35px;
  text-align: center;
  color: #6e6e6e;
  margin-bottom: 35px;
  margin-top: 20px;
  justify-content: center;
    align-content: flex-end;
}

@media (max-width: 1440px) {
  .top-title-commander h1 {
    font-size: 36pt;
  }

  .name-firstPlate {
    font-stretch: condensed;
    font-weight: bold;
    font-size: 16pt;
  }

  .rank-firstPlate {
    font-size: 12pt;

  }

  .text-plateDescription {
    font-size: 12pt;
    margin: 20px 60px;
  }
  .commander-plate {
    height: 100%;
  }
}

@media (max-width: 740px) {
  .container-commander {
    grid-template-columns: 100%;
    justify-content: center;
    grid-gap: 40px;
  }

  .text-plate {
    grid-column: 1 / span 1;
  }

  .top-title-commander h1 {
    font-size: 23pt;
  }


  .text-plateDescription {
    font-size: 10.5pt;
  }

}
