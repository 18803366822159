.wrapper {
    display: grid;
}
.vacancees-image-container {
    margin-top: 130px;
    background-image: url("../../../assets/vacanceesImage.png");
    background-size: cover;
    aspect-ratio: 5.5/1;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    align-content: center;
}

.image-labels-wrapper {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.image-label {
    font-weight: 800;
    font-stretch: condensed;
    color: #ffff;
    font-size: 64px;
    width: 100%;
}

.path {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: #ffff;
    font-size: 24px;
}

.path-label {
    font-weight: 600;
    font-size: 24px;
    font-stretch: condensed;
    text-decoration: none;
}

.path-label-inactive {
    font-weight: 600;
    font-size: 24px;
    font-stretch: condensed;
    text-decoration: none;
    color: #ffff;
    cursor: default;
}

.path-label:hover {
    color: #aaebfd;
}

.vacancees-second-row-wrapper {
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    margin: 7%;
}

.career-nav {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5%;
}
.vacancees-card {
    height: 170px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding-left: 5%;
    cursor: pointer;
}
.officer-icon {
    background-image: url("../../../assets/officerIcon.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50px;
    width: 42px;
}

.sergant-icon {
    background-image: url("../../../assets/sergantIcon.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 43px;
    height: 39px;
}
.sailor-icon {
    background-image: url("../../../assets/sailorIcon.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 16px;
    width: 42px;
}
.vacancees-text {
    font-weight: 700;
    font-size: 16pt;
    margin-left: 5%;
    text-transform: uppercase;
}

.career-main {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.info-wrapper {
    padding: 5%;
}

.vacance-head {
    font-size: 0pt;
    font-weight: 700;
    letter-spacing: 0em;
}

.vacance-separator {
    background-image: url("../../../assets/separatorIcon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 200px;
    margin-top: 2%;
}

.vacance-info {
    margin-top: 2%;
    font-size: 18pt;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #6e6e6e;
}

.officer-image {
    background-image: url("../../../assets/officerImage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    aspect-ratio: 1.5/1;
}

.sergant-image {
    background-image: url("../../../assets/sergantImage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    aspect-ratio: 1.5/1;
}

.sailor-image {
    background-image: url("../../../assets/sailorImage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    aspect-ratio: 1.5/1;
}

@media (max-width: 1200px) {
    .vacancees-second-row-wrapper {
        grid-template-columns: 100%;
        row-gap: 15px;
    }
    
    .vacance-info{
        font-size: 12pt;
    }

    .vacance-head{
        font-size: 20pt;
    }

    .vacancees-card {
        margin-top: 15px;
    }

    .path {
        font-size: 12pt;
        padding-bottom: 20px;
    }

    .image-label {
        padding-top: 20px;
        font-size: 30pt;
    }

    .vacancees-card {
        height: 120px;
    }
}

@media (max-width: 640px) {
    .path {
        display: none;
    }
    .image-label {
        margin-top: 15%;
        margin-bottom: 15%;
        font-size: 20pt;
    }
    .vacancees-text{
        font-size: 12pt;
    }
    .vacance-head{
        font-size: 16pt;
    }
    .vacance-info{
        font-size: 12pt;
    }
}
