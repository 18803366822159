.search-wrapper {
    width: 75%;
    height: 80px;
}

.search {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 90%;
    height: 80px;
}


.search-form-left-content {
    display: flex;
    width: 90%;
}

.cross-icon {
    min-width: 25px;
    width: 15px;
    height: 15px;
    margin-left: 30px;
    cursor: pointer;
    background-image: url("../../assets/cross.svg");
    border: none;
    background-size: contain;
    background-repeat: no-repeat;
    align-self: center;
}

.search-icon {
    min-width: 25px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 30px;
    background-image: url("../../assets/searchIcon.svg");
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    filter: brightness(0) saturate(100%) invert(10%) sepia(33%) saturate(2451%) hue-rotate(180deg) brightness(93%) contrast(105%);
}

.search-form {
  width: 100%;
}

.search-input {
    margin-left: 20px;
    width: 100%;
    border: none;
    font-size: 14pt;
    color: #001B34;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.search-input::placeholder {
    color: #9EBCC5;
}

.search-input:focus {
    outline: none;
}

@media (max-width: 440px) {
    .cross-icon {
        margin-left: 10px;
    }

    .search-icon {
        margin-right: 10px;
    }

    .search-input {
        margin-left: 10px;
    }

    .search-input::placeholder {
        opacity: 0;
    }
}
