.article-allPlates {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    cursor: pointer;
    justify-content: space-between;

}

.article-tagsPag {
    width: 70%;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-right: 5%;
}

.article-all-cotener-information {
    display: flex;
    color: #6f6f6f;
    font-weight: 600;
    font-stretch: condensed;
    margin: 3% 7%;
    height: 100%;
    align-items: flex-end;
}

.article-allHeadline {
    display: flex;
    align-items: center;
}

.allNews .article-headline {
    height: 200px;
}

.allNews .article-headline a {
    color: #86e4ff;
    text-decoration: none;
}

.article-allHeadlineEyes {
    background-image: url("../../assets/EyeIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 25px;
    height: 12.88px;
    padding-right: 2%;
}

.article-Alldate {
    display: flex;
    padding-left: 10%;
    align-items: center;
}

.article-allHeadlineDate {
    background-image: url("../../assets/DateIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 18.11px;
    height: 18px;
}
.article-tagReset {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 11pt;
    font-stretch: condensed;
    text-transform: uppercase;
    background-color: #86e4ff;
    justify-self: center;
    cursor: pointer;
}
.article-headlines {
    font-size: 14pt;
    margin-left: 10px;
}
@media (max-width: 1400px) {
    .article-all-cotener-information {
        font-stretch: condensed;
        justify-content: flex-end;
    }

    .article-Alldate {
        padding-left: 2%;
    }
    .article-headlines {
        font-size: 11pt;
    }

}
@media (max-width: 894px) {
    .article-tagsPag {
        display: flex;
        width: 100%;
        flex-direction: row;
        padding-right: 0;
    }
}

@media (max-width: 744px) {
    .article-tagsPag {
        justify-content: center;
    }
}
