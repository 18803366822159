.language-state{
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    outline: solid 2px white;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
