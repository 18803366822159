.accordion {
}

.accordion-item {
  list-style: none;
  margin-top: 1%;
  min-height: 7rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.accordion-text-wrapper {
  width: 100%;
}

.accordion-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2em;
  height: 100%;
}

.accordion-head {
  font-size: 18pt;
  font-weight: 700;
  font-stretch: condensed;
  margin-left: 1.5em;
}

.accordion-symbol {
  margin-left: 2%;
  color: #ffcf27;
  font-weight: 700;
  font-size: 40px;
}

.accordion-content {
  font-size: 12pt;
  font-weight: 400;
  margin-left: 1em;
  padding: 2%;
  height: 100%;
}

.accordion-link {
  cursor: pointer;
  align-self: flex-start;
  margin-top: 3em;
  margin-right: 2em;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/linkIcon.svg");
}

.accordion-highlight {
  background-color: #FFCF27;
}

.expand-icon {
  background-image: url("../../assets/expandIcon.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  min-width: 30px;
  min-height: 30px;
}

.hide-icon {
  background-image: url("../../assets/hideIcon.svg");
  min-height: 4px;
  min-width: 30px;
}

.article-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: auto auto;
  grid-auto-rows: 0px;
}

@media (max-width: 600px) {
  .accordion-head {
    font-size: 12pt;
  }

  .accordion-content {
    font-size: 10pt;
  }
}
