.wrapper-career {
  display: flex;
  background: url("../../assets/careerImage.png") center/cover fixed no-repeat;
  aspect-ratio: 2/1;
  background-position: left top;
  color: #ffff;
  width: 100%;
  justify-content: end;
  overflow: auto;
}
.right-column-career {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
}

.groupe-career {
  display: grid;
  padding-top: 10%;
  padding-bottom: 15%;
  margin-right: 27%;

}
.one-row-career {
  display: flex;
  flex-wrap: wrap;
  font-weight: 800;
  font-size: 48pt;
  font-stretch: condensed;
  line-height: 75px;
  display: flex;
  align-items: center;
}
.two-row-career {
  font-weight: 700;
  font-size: 24pt;
  font-stretch: condensed;
  line-height: 44px;
  display: flex;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  color: #ffffff;
}
.text-career {
  font-weight: 600;
  font-size: 18pt;
  margin-left: 3%;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.add-element {
  width: 100%;

}
.three-row-career {
  padding-top: 7%;
  display: flex;
  padding-bottom: 2%;
}
.four-career {
  display: flex;
  padding-top: 2%;
}
.color-span {
  color: #37c5ee;
}

.accordion-content p {
  font-size: 12pt;
}

.accordion-content li {
  box-shadow: none;
}

.accordion-content i {
  filter: brightness(0) saturate(100%) invert(79%) sepia(52%) saturate(1176%)
    hue-rotate(172deg) brightness(109%) contrast(101%);
}

@media (max-width: 1440px) {
  .one-row-career {
    font-size: 48px;
    line-height: 65.37px;
  }

  .two-row-career {
    font-size: 24px;
    line-height: 32px;
  }

  .wrapper-career{
    aspect-ratio: 1.3/1;
  }
}

@media (max-width: 740px){
  .wrapper-career{
    justify-content: center;
  }

  .right-column-career{
    width: 100%;
  }

  .groupe-career{
    margin-right: 0;
  }

  .one-row-career, .two-row-career, .three-row-career, .four-row-career{
    text-align: center;
    justify-content: center;
  }

  .one-row-career{
    font-size: 	23pt;
  }

  .two-row-career{
    font-size: 12pt;
    margin: 0 15px;
  }

  .three-row-career, .four-row-career{
    font-size: 19pt;
  }
  .wrapper-career{
    aspect-ratio: 2/2;
  }
}

@media (max-width: 470px){
  .wrapper-career{
    aspect-ratio: 1.3/2;
  }
}

