.wrapper-news {
  display: flex;
  flex-direction: column;
  background: #0b1925;
  z-index: 999;
  padding: 0 7%;
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.main-content-news {
  height: 100%;
}
.main-content-facebook {
  display: flex;
  height: 1670px;
  width: 30%;
  margin-bottom: 2.5%;
  flex: 1 1 30%;
}

.main-page-news-header {
  font-stretch: condensed;
  font-weight: 800;
  font-size: 48pt;
  align-items: center;
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: center;
  text-transform: uppercase;
  color: #86e4ff;
  margin: 90px 0;
}

.main-page-news-subheader {
  color: white;
}

.news {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}
.article-plate {
  cursor: pointer;
  width: 30%;
  margin: 0 3% 3% 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-preview {
  max-height: 280px;
  aspect-ratio: 2 / 1.5;
  /* background-repeat: no-repeat; */
  display: flex;
  height: auto;
  width: 100%;
  object-fit: contain;
  object-position: top;
}

.article-content {
  margin: 3% 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-tags {
  padding: 3% 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  min-height: 50px;
  align-items: center;
}

.article-Alldates {
  display: flex;
  align-items: center;
  margin: 5% 7%;
}

.article-tag {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  padding: 10px 20px;
  font-size: 11pt;
  font-stretch: condensed;
  text-transform: uppercase;
  background-color: #86e4ff;
  justify-self: center;
}

.tag-inactive {
  background-color: #dadada;
}

.tag-inactive:hover {
  cursor: pointer;
  background-color: #86e4ff;
}

.article-title {
  font-size: 18pt;
  font-weight: 600;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
}

.article-headline {
  margin-top: 3%;
  font-size: 14pt;
  color: #8e8e8e;
  min-height: 20px;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-date {
  font-weight: 600;
  font-size: 12pt;
  color: #6f6f6f;
  margin: 5% 7%;
}

.more-news-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
  cursor: pointer;
}

.more-news-icon {
  background-image: url("../../assets/moreNewsIcon.svg");
  height: 70px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.more-news-icon-black {
  background-image: url("../../assets/moreNewsIconBlack.svg");
  height: 70px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.more-new-text {
  font-weight: 700;
  color: white;
  font-size: 18pt;
  font-stretch: condensed;
  text-transform: uppercase;
}

@media (max-width: 990px) {
  .article-plate {
    width: 48%;
    margin-right: 0;
  }
  .main-content {
    flex-direction: column;
  }
  .news {
    width: 100%;
    overflow: auto;
  }
  .main-content-facebook {
    width: 100%;
  }
}
@media (max-width: 1440px) {
  .article-title {
    font-size: 15pt;
  }

  .article-headline {
    font-size: 11pt;
  }

  .article-tag {
    font-size: 11pt;
    padding: 5px 10px;
  }

  .article-date {
    font-size: 12pt;
  }
}

@media (max-width: 744px) {
  .article-plate {
    width: 100%;
    margin: 0 0 5% 0;
  }

  .main-page-news-header {
    font-size: 40pt;
    margin: 60px 0;
  }
}

@media (max-width: 580px) {
  .main-page-news-header {
    font-size: 30pt;
  }
}
