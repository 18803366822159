.detail-news-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 130px;
    align-items: center;
    padding: 0 7% 7% 7%;
    background: url("../../assets/shipFaceNavy.jpg") center/cover fixed
        no-repeat;
    color: #ffff;
}
.detail-news-header {
    margin-top: 5%;
    font-size: 48pt;
    font-weight: 800;
    text-transform: uppercase;
    justify-self: center;
}
.detail-news-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    background-color: white;
    height: 100%;
    width: 100%;
}
.detail-news-navigation {
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    margin-top: 3%;
}
.back-arrow {
    background: url("../../assets/backArrowIcon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.back-arrow:hover {
    fill: aqua;
}

.text-black {
    color: black;
    font-weight: 600;
    font-size: 12pt;
    padding-top: 0;
    text-transform: uppercase;
}

.bread-crumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    margin-left: 20px;
}

.detail-article-header {
    font-size: 30pt;
    font-weight: 600;
    color: black;
    justify-self: center;
    align-self: center;
    margin: 5% 5%;
    /* margin-top: 3%; */
    text-align: center;
}

.detail-article-first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2% 5%;
}

.detail-article-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
}

.detail-article-tags:last-child {
    margin-right: 0;
}
.detail-article-tag {
    font-weight: 700;
    font-size: 15pt;
    color: black;
    background-color: #86e4ff;
    padding: 10px 2%;
    margin-right: 20px;
    margin-top: 10px;
}

.detail-article-content-wrapper {
}
.detail-article-content {
    margin: 0 5%;
    font-size: 12pt;
    color: black;
}
.metadataWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.detail-article-views-count {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.news-count-icon {
    background-image: url("../../assets/EyeIcon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 15px;
    width: 25px;
}
.news-count {
    margin-left: 15px;
    font-size: 14pt;
    font-weight: 600;
    color: #6f6f6f;
}
.detail-article-date {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 40px;
}
.artilce-date-icon {
    background-image: url("../../assets/DateIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
}
.arcticle-date {
    margin-left: 10px;
    font-size: 14pt;
    font-weight: 600;
    color: #6f6f6f;
}

.detail-article-views-count {
}
.detail-article-date {
}

.detail-article-images-wrapper {
    /* align-self: ; */

    margin: 0 20%;
    margin-top: 3%;
}

.detail-more-news-container {
    display: flex;
    flex-direction: column;
}

.detail-article-more-news {
    font-size: 27pt;
    font-weight: 600;
    font-stretch: condensed;
    color: black;
    align-self: center;
    margin: 3% 0;
    text-transform: uppercase;
}

.detail-more-news {
    height: 100%;
    margin: 0 5%;
}
.detail-news-plate {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    margin-bottom: 2%;
}
.detail-plate-preview {
    aspect-ratio: 4/3;
    height: 200px;
}
.news-plate-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1% 0;
    margin-left: 3%;
    width: 100%;
}
.plate-content-header {
    font-size: 18pt;
    font-weight: 600;
    color: black;
}
.plate-content-headline {
    margin-top: 2%;
    font-size: 12pt;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #8e8e8e;
}

.plate-content-bottom-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.plate-content-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
}

.plate-content-tags:last-child {
    margin-right: 0;
}

.plate-content-tag {
    padding: 10px 2%;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 12pt;
    color: black;
    font-weight: 700;
    background-color: #86e4ff;
}

.detail-carouse-item {
    /* margin: 20%; */
    /* height: 500px; */
    /* width: 600px; */
}

@media (max-width: 1400px) {
    .metadataWrapper{
        align-items: flex-start;
    }
    .detail-article-views-count{
        margin-left: 33px;
    }
    .arcticle-date{
        font-size: 11pt;
    }
    .news-count{
        font-size: 11pt;
    }
}

@media (max-width: 1000px) {
    .detail-article-date{
        margin-left: 0;
    }
    .detail-article-header {
        font-size: 20pt;
    }

    .detail-news-header {
        display: none;
    }

    .detail-article-tag {
        font-size: 12pt;
    }

    .detail-article-more-news {
        font-size: 20pt;
    }
    .plate-content-header {
        font-size: 14pt;
    }

    .plate-content-tags {
        display: none;
    }

    .detail-article-images-wrapper {
        margin: 0 15%;
    }
    .detail-article-views-count{
        display: none;
    }

}

@media (max-width: 750px) {
    .detail-plate-preview {
        height: 100px;
        /* flex-direction: column; */
    }
    .news-plate-content {
        margin-top: 0;
    }

    .detail-article-tag {
        font-size: 12pt;
    }

    .detail-news-header {
        font-size: 30pt;
    }

    .detail-article-header {
        font-size: 18pt;
    }

    .detail-article-tags {
        display: none;
    }

    .detail-article-images-wrapper {
        margin: 0 10%;
    }
    .plate-content-headline {
        margin: 2% 0 2% 0;
    }

}

@media (max-width: 500px) {
    .detail-plate-preview {
        display: none;
    }

    .detail-news-plate {
        margin-bottom: 20px;
    }

    .detail-article-header {
        font-size: 14pt;
    }

    .detail-news-header {
        font-size: 24pt;
    }

    .detail-article-images-wrapper {
        margin: 0 5%;
    }
}
