.pagination {
  margin-top: 70px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
}

.page-control {
  width: 100%;
  display: flex;
}

.page-control > div {
  cursor: pointer;
}

.navigateButton {
  height: 30px;
  width: 30px;
  background-image: url("../../assets/paginationNextIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
}

.inactiveButton {
  background-image: url("../../assets/paginationNextInactiveIcon.svg");
}

.paginationBlock {
  margin-left: 15px;
  height: 30px;
  width: 30px;
  padding-top: 4px;
  box-sizing: border-box;
  border: 3px solid #67DDFF;
  border-radius: 6px;
  font-size: 11pt;
  font-weight: bold;
  text-align: center;
}

.paginationPageSelected {
  background-color: #67DDFF;
}

.nextButton {
  margin-left: 15px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.pageSizeDropdown {
  height: 30px;
  width: 60px;
  background-color: white;
  outline: none;
}

.pageSizeDropdown option {
  border: none;
}

.pageSizeDropdown i {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  background-image: url("../../assets/dropDownIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
