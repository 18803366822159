.wrapper {
    display: grid;
}

.first-row {
    height: 370px;
}

.image-container {
    margin-top: 130px;
    background-image: url("../../assets/shipsImage.png");
    background-size: cover;
    aspect-ratio: 5.5/1;
    background-repeat: no-repeat;
    background-position: 60% 50%;
    display: flex;
    align-items: center;
    align-content: center;
}

.image-labels-wrapper {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
}

.path-label {
    font-weight: 600;
    font-size: 24px;
    color: #ffff;
    padding-top: 3rem;
}

.path-label.active {
    color: #aaebfd;
}

.main-zone {
    display: grid;
    grid-template-columns: repeat(2, minmax(30%, 1fr));
    grid-gap: 2rem;
    padding: 10%;
}
.career-card {
    /* border: solid black 1px ; */
    height: 450px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.vacancees-icon {
    background-image: url("../../assets/yellowAnchorIcon.svg");
    fill: black;
    height: 80px;
    width: 52px;
    margin-top: 7%;
}

.contract-icon {
    background-image: url("../../assets/crosshaidIcon.svg");
    margin-top: 7%;
    height: 70px;
    width: 70px;
}

.centers-icon {
    background-image: url("../../assets/radioIcon.svg");
    margin-top: 7%;
    width: 38px;
    height: 94px;
}

.card-label {
    margin-top: 5%;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-stretch: condensed;
}

.separator {
    background-image: url("../../assets/separatorIcon.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 200px;
    margin-top: 7%;
}

.details-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.details-label {
    font-weight: 700;
    font-size: 20px;
}

.arrow-right {
    background-image: url("../../assets/arrowRight.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
}

@media (max-width: 1200px) {
    .card-label {
        font-size: 24pt;
    }

    .career-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
    }
}

@media (max-width: 800px) {
    .main-zone {
        grid-template-columns: 100%;
    }

    .card-label {
        max-width: 300px;
    }

    .career-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
    }

    .vacancees-icon,
    .contract-icon,
    .centers-icon {
        height: 50px;
        width: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 0;
    }

    .card-label {
        font-size: 19pt;
    }
    .image-label {
        font-size: 20pt;
    }
}
